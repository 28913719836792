import React, { useLayoutEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'components/Loader';

export default function AuthLogout() {
  const { logout } = useAuth0();

  useLayoutEffect(() => {
    if (typeof window !== undefined) {
      logout({ returnTo: window.location.origin });
    }
  }, []);

  return <Loader isOpen={true} />;
}
